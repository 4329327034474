.container {
    padding: 30px 10px;
}

.container p {
    text-align: center;
    font-size: 14px;
    line-height: 2;
}

.container img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #7d71cf;
    object-fit: contain;
}