.model_setting {
    width: 180px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid #7e71cf4f;
    user-select: none;
    position: fixed;
    z-index: 9999;
}

.header {
    font-size: 12px;
    padding: 3px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
}

.tools {
    background-color: white;
    min-height: 100px;
    padding: 8px;
}

.tools>div {
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 10px;
    box-shadow: 0 0 1px 1px rgb(128 128 128 / 15%);
}

.tools .draggable {
    text-align: center;
    font-size: 12px;
    padding: 5px 0;
    cursor: grab;
}