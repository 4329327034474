.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.content {
    position: relative;
    z-index: 1;
}

.mask_wrapper {
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 60%);
    position: absolute;
    z-index: 999
}

.mask_wrapper_show {
    opacity: 1;
}

.mask_wrapper_hidden {
    opacity: 0;
    z-index: 0;
}

.mask {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mask_content {
    display: flex;
    align-items: center;
    justify-content: center;
}