.nav {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
}

.nav_full_screen span {
    color: white;
    margin-right: 25px;
    font-size: 16px;
}

.nav_full_screen span:last-child {
    margin-right: 0;
}

.nav_full_screen a {
    transition: all 0.2s;
}

.nav_full_screen span:hover a {
    color: #7d71cf;
}

/* 响应式设计 */
.nav_middle_screen_wrapper {
    display: none;
    font-size: 20px;
    color: white;
}

.nav_middle_screen {
    position: fixed;
    left: 0;
    top: 80px;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 9999;
    background-color: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
}

.nav_middle_screen>a {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: #7d71cf;
    border-bottom: 1px solid #7d71cf;
    transition: all 0.2s;
}

.nav_middle_screen>a:hover {
    color: white;
    background-color: #7e71cfaf;
    border-bottom: 1px solid white;
}

/* 媒体查询 */
@media screen and (max-width:1000px) {
    .nav_full_screen {
        display: none;
    }

    .nav_middle_screen_wrapper {
        display: block;
    }
}