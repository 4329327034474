.container_wrapper {
    width: 100%;
    height: 100%;
    min-width: 1080px;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    height: 80px;
    flex-shrink: 0;
    background-color: black;
}

.slogan {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slogan img {
    width: 200px;
}

.content {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    background-color: #F4F7FC;
    height: 100%;
    overflow: auto;
}

.left {
    width: 250px;
    flex-shrink: 0;
    background-color: white;
}

.left>ul {
    height: 100%;
}

.right {
    flex: 1;
    width: 100%;
    overflow: auto;
}