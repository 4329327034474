.container {
    padding: 8px;
    user-select: none;
}

.progress {
    position: relative;
    height: 4px;
    background-color: black;
    margin-top: 5px;
    cursor: pointer;
}

.bar {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: orange;
    top: -3px;
}

.manual {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.manual_changer {
    display: flex;
    align-items: center;
    color: orange;
}

.manual_changer>span:nth-child(2) {
    padding: 0 5px;
}