body {
    background-color: #F6F6F6;
}

.container {
    width: 100%;
}

.content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 30px;
}

.left {
    width: 250px;
    flex-shrink: 0;
    background-color: white;
    box-shadow: 0 0 1px 1px #DFDFDF;
    border-radius: 5px;
    height: 100%;
}

.right {
    margin-left: 30px;
    flex: 1;
    background-color: white;
    box-shadow: 0 0 1px 1px #DFDFDF;
    border-radius: 5px;
    min-height: 500px;
}

/* 媒体查询 */
@media screen and (max-width:1000px) {
    .left {
        display: none;
    }

    .right {
        margin-left: 0;
    }
}

@media screen and (max-width:600px) {
    .content {
        padding: 20px;
        margin-top: 80px;
    }
}