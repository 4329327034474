.header_wrapper {
    width: 100%;
    background-color: black;
}

.header {
    max-width: 1000px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    padding: 0 30px;
}

.left {
    width: 250px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
}

.search {
    display: flex;
    align-items: center;
    justify-self: flex-end;
}

.search_input {
    width: 200px;
}

.search_icon {
    margin-left: 30px;
    display: none;
}

/* 媒体查询 */
@media screen and (max-width:1000px) {
    .search_icon {
        display: block;
        color: white;
        font-size: 20px;
    }

    .search_input {
        display: none;
    }

    .left {
        width: auto;
    }

    .right {
        justify-content: flex-end;
    }
}

@media screen and (max-width:600px) {
    .search_icon {
        margin-left: 20px;
    }

    .header {
        padding: 0 20px;
    }

    .header_wrapper {
        position: fixed;
        top: 0;
        z-index: 9999;
    }
}