.panel_mark {
    position: fixed;
    z-index: 99;
}

.nepmark {
    width: 35px;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}