* {
    padding: 0;
    margin: 0;
}

html, body, #root {
    width: 100%;
    height: 100%;
}

#root {
    min-width: 300px;
}

a {
    color: white;
    text-decoration: none;
}