.container img {
    width: 200px;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
}

/* 媒体查询 */
@media screen and (max-width:600px) {
    .container img {
        width: 150px;
    }
}